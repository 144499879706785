<template>
  <v-container
    fluid
    class="pt-0"
  >
    <base-material-card
      color="primary"
      icon="mdi-map-plus"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="font-weight-light truncate-title-rt fs-rt">
          {{ titulo }}
        </div>
      </template>
      <v-btn
        v-if="accessReleased('CANVAS_ADICIONAR')"
        id="btn-novo-canvas"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push('edit')"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Novo Mapa
      </v-btn>
      <v-text-field
        id="canvas-historico-search"
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />
      <v-divider class="mt-10" />
      <v-data-table
        :headers="headers"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhum mapa disponível"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id }}
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="text-truncate-column"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.nome }}
              </div>
            </template>
            <span>{{ item.nome }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.numero_layers`]="{ item }">
          {{ item.numero_layers | parseNumberToIntegerBR }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="accessReleased('CANVAS_VISUALIZAR_MAPA')"
            id="action-visualizar"
            color="gray"
            min-width="0"
            class="px-1 ml-n1"
            fab
            icon
            x-small
            @click="$router.push(`visualizar-mapa/${item.id}`)"
          >
            <v-icon small> mdi-eye-outline </v-icon>
          </v-btn>
          <v-btn
            v-if="accessReleased('CANVAS_EDITAR')"
            id="action-edit-rel"
            color="gray"
            min-width="0"
            class="px-1 ml-n1"
            fab
            icon
            x-small
            @click="$router.push(`edit/${item.id}`)"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            id="action-favoritar-rel"
            class="px-1 ml-n1"
            :color="item.favorito ? 'primary' : 'gray'"
            min-width="0"
            fab
            icon
            x-small
            @click="favoritar(item)"
          >
            <v-icon small> mdi-star </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more-rel"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="duplicarItem(item.id)">
                <v-list-item-title>
                  <v-icon small> mdi-content-copy </v-icon>
                  Duplicar
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="canDeleteItem(item)"
                @click="openDialogDelete(item.id, item.nome)"
              >
                <v-list-item-title>
                  <v-icon small> mdi-trash-can-outline </v-icon>
                  Deletar
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  (dialogCreationLog = true),
                    (dialogCreationLogData = {
                      username: item.usuario,
                      created: item.created
                    })
                "
              >
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable')"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Clique aqui para recarregar os mapas</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title">Deletar Mapa #{{ dialogDeleteData.id }}</template>
      <template slot="body">
        Tem certeza que deseja deletar o mapa
        <strong>{{ dialogDeleteData.titulo }}</strong
        >?
      </template>
    </dialog-delete>
  </v-container>
</template>

<script>
import CanvasService from '@/services/CanvasService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [powerupsActionsMixins, profilePermissionsMixin],

  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    DialogDelete: () => import('@/components/general/DialogDelete')
  },

  props: {
    titulo: {
      type: String,
      default: 'Mapa'
    },
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    powerupService: CanvasService,
    powerupEntityName: 'Mapa',
    search: '',
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Nome',
        value: 'nome'
      },
      {
        text: 'Nº de layers',
        value: 'numero_layers'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ]
  }),

  methods: {
    favoritar(item) {
      CanvasService.favoritar(item.id)
        .then(() => (item.favorito = !item.favorito))
        .catch(() =>
          this.$toast.error('Erro ao tentar favoritar/desfavoritar.', '', {
            position: 'topRight'
          })
        );
    }
  }
};
</script>

<style>
.truncate-title-rt {
  width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fs-rt {
  font-size: 1.5625rem;
}
@media screen and (max-width: 1100px) {
  .truncate-title-rt {
    width: 550px;
  }
}
@media screen and (max-width: 800px) {
  .fs-rt {
    font-size: 1.2625rem;
  }
  .truncate-title-rt {
    width: 350px;
  }
}
@media screen and (max-width: 550px) {
  .fs-rt {
    font-size: 1.12rem;
  }
}
</style>
